import { useState } from "react";
import {
  Input,
  Button,
  makeStyles,
  shorthands,
  Label,
  tokens,
  MessageBar,
  MessageBarBody,
  Link,
  MessageBarTitle,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "20px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalXXS,
    marginTop: tokens.spacingVerticalMNudge,
    ...shorthands.padding(tokens.spacingHorizontalMNudge),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    width: "100%",
    ...shorthands.gap("15px"),
  },
  logo: {
    width: "150px", // Adjust the size as needed
    marginBottom: "20px",
  },
});

export interface ILandingPageParams {
  name: string;
  email: string;
}

const LandingPage = (params: ILandingPageParams) => {
  const styles = useStyles();

  var sp = new URLSearchParams(window.location.search);
  const token: string = sp.get("token") ?? "-";

  const [name, setName] = useState(params.name);
  const [email, setEmail] = useState(params.email);
  const [message, setMessage] = useState({ type: "", content: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!name || !email) {
      setMessage({ type: "error", content: "Please fill in both fields." });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch("/api/Register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, token }),
      });

      if (response.ok) {
        setMessage({ type: "success", content: "We would contact you with details required to start using the system" });
        setName("");
        setEmail("");
      } else {
        setMessage({
          type: "error",
          content: "Submission failed. Please try again.",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        content: "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <img
          src="skyarcs-solutions-logo.png"
          alt="Logo"
          className={styles.logo}
        />
        <h1>SkyArcs registration</h1>
        <Label>
          Please provide the name and email you want to be used in the
          application.
        </Label>
        <div className={styles.field}>
          <Label htmlFor={name}>Name</Label>
          <Input
            id={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className={styles.field}>
          <Label htmlFor={email}>Email</Label>
          <Input
            id={email}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <Button appearance="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
        {message.content && (
          <MessageBar intent={message.type === "success" ? "success" : "error"}>
            <MessageBarBody>
              <MessageBarTitle>
                {message.type === "success" ? "Success" : "Fail"}
              </MessageBarTitle>
              {message.content}
            </MessageBarBody>
          </MessageBar>
        )}
      </form>
    </div>
  );
};

export default LandingPage;
