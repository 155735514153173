import { FC, Fragment, useEffect, useState } from "react";
import "./App.css";
import {
  Button,
  FluentProvider,
  makeStyles,
  webLightTheme,
} from "@fluentui/react-components";
import LandingPage from "./LandingPage";
import { loginRequest } from "./authConfig";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Admin from "./Admin";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "20px",
  },
});

const App: FC<any> = ({ instance }) => {
  const styles = useStyles();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const { accounts } = useMsal();

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        await instance.initialize();
        instance.addEventCallback((event: any) => {
          if (event.eventType === EventType.LOGIN_SUCCESS) {
            const accounts = instance.getAllAccounts();
            instance.setActiveAccount(accounts[0]);
            const currentAccount: any = instance.getActiveAccount();
            setName(currentAccount.name);
            setEmail(currentAccount.username);
          }
        });
        if (instance.getAllAccounts().length > 0) {
          const currentAccount: any = instance.getActiveAccount();
          setName(currentAccount.name);
          setEmail(currentAccount.username);
        }
      } catch (error: any) {
        console.error("Error:", error);
      }
      return () => {};
    };

    fetchAuth();
  }, [accounts, instance]);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.log(e);
    });
  };

  return (
    <FluentProvider theme={webLightTheme}>
      <AuthenticatedTemplate>
        <Router>
          <Fragment>
            <Routes>
              <Route path="/admin" element={<Admin />} />
              <Route
                path="/"
                element={<LandingPage name={name} email={email} />}
              />
            </Routes>
          </Fragment>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className={styles.container}>
          <Button appearance="primary" onClick={() => handleLogin()}>
            Please sign in to your organization AAD
          </Button>
        </div>
      </UnauthenticatedTemplate>
    </FluentProvider>
  );
};

export default App;
